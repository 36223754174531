import { IconButton } from '@atlaskit/button/new';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { LoadingButton } from '@atlaskit/button';
import { Inline, Stack } from '@atlaskit/primitives';
import { useEffect, useState, useRef } from 'react';
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Issue, IssueVoting } from '../../../types';
import { getIssueVoting } from '../../data/actions';
import { useGameContext } from '../../hooks/useGameContext';
import Results from '../Stats/Results';
import { TableResults } from './TableResults/TableResults';
import styles from './IssueResultsModal.module.scss';
import {
  getMinutesAndSecondsFromMilis,
  getMonthWithDayAndTimeInLocalTimeShort,
} from '@we-agile-you/js-base';
import Tooltip from '@atlaskit/tooltip';
import { Loader } from '../../../../components/Loader/Loader';
import { StatsKpi } from '../Stats/StatsKpi/StatsKpi';
import { openIssueModal } from '../../../jira/actions';

interface ImportFromFileModalProps {
  issue: Issue;
  onClose: () => void;
}
export const IssueResultsModal = ({
  issue,
  onClose,
}: ImportFromFileModalProps) => {
  const gameContext = useGameContext();
  const appId = useAuthContext().appId;

  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [voting, setVoting] = useState<IssueVoting | null>(null);
  const [votingId, setVotingId] = useState<string | null>(null);
  const [isLoadingIssueModal, setIsLoadingIssueModal] = useState(false);
  const focusRef = useRef<HTMLSpanElement>(null);

  const gameId = gameContext?.game?.id;

  useEffect(() => {
    if (votingId) {
      return;
    }

    const firstVotingId =
      issue?.votings?.length && issue.votings[issue.votings.length - 1];

    if (firstVotingId) {
      setVotingId(firstVotingId);
    }
  }, [issue, votingId]);

  useEffect(() => {
    if (!votingId || !gameId) {
      return;
    }

    setIsLoading(true);
    getIssueVoting({ votingId, gameId, appId }).then((voting) => {
      setVoting(voting);
      setIsLoading(false);
    });
  }, [votingId, gameId]);

  const currentVotingIndex =
    issue.votings?.findIndex((voting) => voting === votingId) || 0;
  const votingsTotal = issue.votings?.length || 0;

  const handlePreviousVoting = () => {
    const newVotingId = issue.votings && issue.votings[currentVotingIndex - 1];
    if (newVotingId) {
      setVotingId(newVotingId);
    }
  };
  const handleNextVoting = () => {
    const newVotingId = issue.votings && issue.votings[currentVotingIndex + 1];
    if (newVotingId) {
      setVotingId(newVotingId);
    }
  };
  const handleOpenIssue = () => {
    setIsLoadingIssueModal(true);

    openIssueModal(issue.key, () => {
      setIsLoadingIssueModal(false);
    });
  };

  return (
    <Modal
      onClose={onClose}
      width="x-large"
      shouldScrollInViewport
      autoFocus={focusRef}
    >
      <ModalHeader>
        <ModalTitle>
          <LoadingButton
            isLoading={isLoadingIssueModal}
            appearance="link"
            onClick={handleOpenIssue}
            spacing="none"
          >
            {issue.key}
          </LoadingButton>
          <span>{` ${voting?.issueSummary || ''}`}</span>
        </ModalTitle>
        <Inline alignBlock="center" space="space.075">
          <IconButton
            icon={ChevronLeftIcon}
            onClick={handlePreviousVoting}
            isDisabled={currentVotingIndex === 0}
            label="Previous voting"
          />

          <Tooltip
            content={
              <>
                {voting?.timestamp ? (
                  <>{`Date: ${getMonthWithDayAndTimeInLocalTimeShort(voting?.timestamp)}`}</>
                ) : null}
              </>
            }
          >
            {(tooltipProps) => (
              <div {...tooltipProps}>
                <Inline alignBlock="center">
                  <span className={styles['voting-label']}>{`Voting `}</span>
                  <span>{`${currentVotingIndex + 1}/${votingsTotal}`} </span>
                  <EditorPanelIcon label="Details" />
                </Inline>
              </div>
            )}
          </Tooltip>

          <IconButton
            icon={ChevronRightIcon}
            onClick={handleNextVoting}
            isDisabled={
              !issue.votings?.length ||
              currentVotingIndex === issue.votings?.length - 1
            }
            label="Next voting"
          />
        </Inline>
      </ModalHeader>
      <ModalBody>
        <div className={styles.wrapper}>
          {isLoading ? (
            <Loader />
          ) : (
            voting && (
              <Stack space="space.100">
                <Inline alignInline="center">
                  <TableResults voting={voting} />
                </Inline>
                <Inline alignInline="center" alignBlock="start">
                  <div style={{ height: 200 }}>
                    <Results
                      resultsOverwrite={voting.results}
                      deckCardsOverwrite={voting.deckCards}
                    />
                  </div>
                  <StatsKpi label="Result:" value={voting.cardResult} />
                </Inline>
              </Stack>
            )
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

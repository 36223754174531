import { AuthContext } from './spaces/auth/AuthContext';
import { NavigationContext } from './spaces/navigation/NavigationContext';
import { Router } from './spaces/navigation/Router';
import { useNavigation } from './spaces/navigation/useNavigaton';
import styles from './App.module.scss';
import { Loader } from './components/Loader/Loader';
import InlineMessage from '@atlaskit/inline-message';
import { SIGNIN_ERROR_MESSAGES, useSignIn } from './spaces/auth/useSignin';
import { useSubscribeToCurrentUser } from './spaces/auth/useSubscribeToCurrentUser';
import { useSubscribeToAppData } from './spaces/auth/useSubscribeToAppData';
import { useSubscribeToAppUsers } from './spaces/auth/useSubscribeToAppUsers';
import { Stack } from '@atlaskit/primitives';

function App() {
  const navigation = useNavigation();
  const auth = useSignIn();
  const user = useSubscribeToCurrentUser(auth);
  const appData = useSubscribeToAppData(auth);
  const appUsers = useSubscribeToAppUsers(user, auth);

  if (auth === null) {
    return (
      <div className={styles['loading-container']}>
        <Loader message="Loading app..." />{' '}
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    const errorCode = auth.error?.message;
    const errorMessage =
      (errorCode &&
        SIGNIN_ERROR_MESSAGES[
          errorCode as keyof typeof SIGNIN_ERROR_MESSAGES
        ]) ||
      'An unexpected error occurred';

    if (errorCode === 'NOT_LICENSED') {
      return (
        <Stack alignInline="center" space="space.500">
          <div />
          <div />
          <InlineMessage title="Not licensed">
            <p>{errorMessage}</p>
          </InlineMessage>
          <a
            href="https://marketplace.atlassian.com/apps/1233326/planning-poker-story-point-estimations-scrum-poker?hosting=cloud&tab=overview"
            target="_blank"
          >
            Activate or renew license
          </a>
          <div />
        </Stack>
      );
    }

    return (
      <Stack alignInline="center" space="space.500">
        <div />
        <div />
        <InlineMessage
          title="Auth error"
          secondaryText="There was an unexpected authentication error"
        >
          {errorMessage}
        </InlineMessage>
        <div />
      </Stack>
    );
  }

  return (
    <AuthContext.Provider value={{ ...auth, user, appData, appUsers }}>
      <NavigationContext.Provider value={navigation}>
        <Router />
      </NavigationContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;

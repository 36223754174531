import cx from 'classnames';
import { useEffect, useState } from 'react';
import { usePlayerDistribution } from '../../../hooks/usePlayerDistribution';
import { IssueVoting, Player as PlayerType } from '../../../../types';
import { throttle } from 'lodash';

import styles from './Table.module.scss';
import { Player } from '../../Table/Player/Player';
import { TopLane } from '../../Table/TopLane/TopLane';

const USE_PANEL_POPUP_MEDIA_QUERY = 920;

export const TableResults = ({ voting }: { voting: IssueVoting }) => {
  const [layout, setLayout] = useState<'vertical' | 'horizontal' | null>(null);
  const { playerDistribution } = usePlayerDistribution(
    layout,
    voting.playersOnline,
    voting.results,
  );

  const players = voting.playersOnline;

  useEffect(() => {
    setLayout(
      window.innerWidth < USE_PANEL_POPUP_MEDIA_QUERY
        ? 'vertical'
        : 'horizontal',
    );

    const handleWindowResize = throttle(() => {
      setLayout(
        window.innerWidth < USE_PANEL_POPUP_MEDIA_QUERY
          ? 'vertical'
          : 'horizontal',
      );
    }, 1000);

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (!players || !playerDistribution) {
    return null;
  }

  const isCrowded = players.length && players.length > 10;

  const renderPlayer = (
    player: PlayerType,
    position: 'top' | 'left' | 'right' | 'bottom',
  ) => {
    const hasProjectileSelector = false;

    return (
      <div className={styles['player-container']}>
        <Player
          player={player}
          position={position}
          onIsProjectilePickerOpenChange={() => {}}
          hasProjectileSelector={!!hasProjectileSelector}
          onShootProjectile={() => {}}
          onProjectileShootComplete={() => {}}
          incomingProjectiles={[]}
          isCurrentUserPlayer={false}
          isCurrentUserTheOwner={false}
          onChangePoints={() => {}}
          cardsUpOverwrite
          isResultsTable
        />
      </div>
    );
  };

  return (
    <div
      className={cx(styles.wrapper, isCrowded && styles['wrapper--is-crowded'])}
      data-test="table"
    >
      <div className={cx(styles.container)}>
        <div />
        <div className={cx(styles.top)}>
          <TopLane isUserLonely={false}>
            {playerDistribution.top.map((player) =>
              renderPlayer(player, 'top'),
            )}
          </TopLane>
        </div>
        <div />
        <div className={styles.left}>
          {playerDistribution.left.map((player) =>
            renderPlayer(player, 'left'),
          )}
        </div>

        <div className={cx(styles.table)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div className={styles['table-content']}></div>
        </div>
        <div className={styles.right}>
          {playerDistribution.right.map((player) =>
            renderPlayer(player, 'right'),
          )}
        </div>
        <div />
        <div className={styles.bottom}>
          {playerDistribution.bottom.map((player) =>
            renderPlayer(player, 'bottom'),
          )}
        </div>
        <div />
      </div>
    </div>
  );
};

import { updateIssueStoryPoints } from './../data/actions';
import { useEffect, useState } from 'react';
import { useGameContext } from './useGameContext';
import { useAuthContext } from '../../auth/useAuthContext';
export const useHandleUnsynchedIssue = () => {
  const gameContext = useGameContext();
  const authContext = useAuthContext();
  const [isTriggered, setIsTriggered] = useState(false);

  const currentUserUid = authContext.uid;
  const appId = authContext && authContext.appId;
  const gameId = gameContext && gameContext.game?.id;
  const storyPointsField = gameContext?.game?.storyPointsField || null;

  const currentIssue =
    gameContext?.game?.issues &&
    typeof gameContext?.currentIssueIndex === 'number'
      ? gameContext?.game?.issues[gameContext?.currentIssueIndex]
      : null;

  const hasToUpdateIssueId =
    currentIssue?.unsyncCausedByUid &&
    currentIssue?.unsyncCausedByUid === currentUserUid
      ? currentIssue.id
      : null;

  const storyPoints = currentIssue?.storyPoints;

  useEffect(() => {
    if (
      isTriggered ||
      !hasToUpdateIssueId ||
      typeof storyPoints !== 'string' ||
      !gameId ||
      !storyPointsField
    ) {
      return;
    }

    setIsTriggered(true);

    console.log('updateing IssueStoryPoints in jira');

    updateIssueStoryPoints({
      appId,
      gameId,
      issueId: hasToUpdateIssueId,
      storyPoints,
      storyPointsField,
    }).finally(() => {
      setIsTriggered(false);
    });
  }, [hasToUpdateIssueId, storyPoints, appId, gameId]);
};

import { Issue } from '@we-agile-you/types-planning-poker';
import React, { useCallback } from 'react';

import { IssueCard } from './IssueCard/IssueCard';
import styles from './IssueListSidebar.module.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useIssueActions } from '../../../../spaces/poker-table/hooks/useIssueActions';
import withScrolling from 'react-dnd-scrolling';
import { AddIssueInline } from '../../AddIssueInline/AddIssueInline';

interface IssueListSidebarProps {
  issues?: Issue[];
  onOpenIssue: (issue: Issue) => void;
  onOpenVotingResults: (issue: Issue) => void;
  onDeleteIssue: (issue: Issue) => void;
  onMoveIssue: () => void;
  issuesContainerEnd: React.RefObject<HTMLDivElement>;
}

const ScrollingComponent = withScrolling('ul');
export const IssueListSidebar = ({
  issues,
  onOpenIssue,
  onOpenVotingResults,
  onDeleteIssue,
  onMoveIssue,
  issuesContainerEnd,
}: IssueListSidebarProps) => {
  const { moveIssue } = useIssueActions();

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      moveIssue(dragIndex, hoverIndex);
      onMoveIssue();
    },
    [moveIssue, onMoveIssue],
  );
  const handleIssueAdded = () => {
    if (issuesContainerEnd.current) {
      issuesContainerEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ScrollingComponent className={styles.list}>
        {issues?.map(
          (issue, i) =>
            issue.key && (
              <IssueCard
                key={issue.key}
                index={i}
                issue={issue}
                moveCard={moveCard}
                onDeleteIssue={onDeleteIssue}
                onOpen={() => {
                  onOpenIssue(issue);
                }}
                onOpenResults={() => {
                  onOpenVotingResults(issue);
                }}
              />
            ),
        )}
        <div ref={issuesContainerEnd} /> {/* TODO:: MAKE THIS WORK */}
      </ScrollingComponent>
      <div className={styles['add-issue']}>
        <AddIssueInline onIssueAdded={handleIssueAdded} />
      </div>
    </DndProvider>
  );
};

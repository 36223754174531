import { Player, Results } from '../../types';

export const getAverageFromResults = (results?: Results): number | null => {
  const totalPlayers = new Array((results && results.total) || 0).fill('');
  let pointSum: number = -1;
  let noNumberVotes = 0;

  if (results) {
    pointSum = results.votes.reduce((pointSum, vote) => {
      const number = vote.card.trim() === '½' ? 0.5 : Number(vote.card);

      if (isNaN(number)) {
        noNumberVotes += vote.totalVotes;
        return pointSum;
      }

      return pointSum === -1
        ? number * vote.totalVotes
        : pointSum + number * vote.totalVotes;
    }, pointSum);
  }

  const totalNumberVotes = totalPlayers.length - noNumberVotes;

  const average =
    pointSum !== -1 && totalNumberVotes > 0
      ? Math.round((pointSum / totalNumberVotes) * 100) / 100
      : null;

  return average;
};

export const mapPlayersToResults = (players: Player[]): Results => {
  const defaultResults: Results = { total: 0, votes: [] };
  return players.reduce((results, player) => {
    if (player.vote) {
      const cardIndex = results.votes.findIndex(
        (value) => value.card === player.vote,
      );
      if (cardIndex !== -1) {
        results.votes[cardIndex].totalVotes += 1;
        results.votes[cardIndex].playersVoted.push(player);
      } else {
        results.votes.push({
          card: player.vote,
          totalVotes: 1,
          playersVoted: [player],
        });
      }
      results.total += 1;
    }
    return results;
  }, defaultResults);
};

export const getClosestCardToAverage = (
  average: number | null,
  cards: string[],
): string | null => {
  let mostVotedCard: string | null = null;

  if (average) {
    for (let i = 0; i < cards.length - 1; ++i) {
      const cardNumber = Number(cards[i]);
      const nextCardNumber = Number(cards[i + 1]);

      if (average === cardNumber) {
        mostVotedCard = cards[i];
      }
      if (average === nextCardNumber) {
        mostVotedCard = cards[i + 1];
      } else if (
        !isNaN(cardNumber) &&
        !isNaN(nextCardNumber) &&
        average > cardNumber &&
        average < nextCardNumber
      ) {
        mostVotedCard =
          average - cardNumber >= nextCardNumber - average
            ? cards[i + 1]
            : cards[i];
      }
    }
  }

  return mostVotedCard;
};

export const getCardWithMoreVotes = (results: Results): string => {
  const mostVoted = results.votes.reduce((mostVotted, vote) => {
    if (vote.totalVotes > mostVotted.totalVotes) {
      return vote;
    }

    return mostVotted;
  }, results.votes[0]);

  return mostVoted.card;
};
export const COFFE_TIME = 'COFFE_TIME';

export const getAgreementFromResults = (
  deckArray: string[],
  results: Results,
) => {
  const totalCards = deckArray.filter((card) => card !== '☕').length;
  const votes = results.votes.filter((vote) => vote.card !== '☕');

  const totalVotesWithCoffes = results.votes.reduce(
    (total, vote) => total + vote.totalVotes,
    0,
  );
  const totalVotes = votes.reduce((total, vote) => total + vote.totalVotes, 0);
  const coffes = totalVotesWithCoffes - totalVotes;

  if (coffes > 0 && coffes >= Math.floor(totalVotes / 2)) {
    return COFFE_TIME;
  }

  let mostVoted = votes?.length && [votes[0]];

  if (!mostVoted) return 0;

  for (const vote of votes) {
    if (vote.totalVotes > mostVoted[0].totalVotes) {
      mostVoted = [vote];
    }

    if (vote.totalVotes === mostVoted[0].totalVotes) {
      mostVoted.push(vote);
    }
  }

  let mostVotedCards = mostVoted.map((vote) => vote.card.trim());
  mostVotedCards = mostVotedCards.filter(
    (card, index) => mostVotedCards.indexOf(card) === index,
  );

  const mostVotedCardsIndexes = mostVotedCards
    .map((card) => deckArray.indexOf(card))
    .filter((index) => index >= 0);

  if (!mostVotedCardsIndexes.length) {
    return null;
  }

  const deviation = votes.reduce((deviation, vote) => {
    const card = vote.card.trim();

    const cardIndex = deckArray.indexOf(card);

    if (cardIndex < 0 || card === '?') return deviation;

    const cardDeviation = mostVotedCardsIndexes.reduce(
      (cardDeviation, mostVotedCardIndex) => {
        if (
          mostVotedCardIndex === cardIndex &&
          mostVotedCardsIndexes.length > 1
        ) {
          return cardDeviation;
        }

        const deviation = Math.pow(cardIndex - mostVotedCardIndex, 2);

        if (deviation < cardDeviation) {
          return deviation;
        }

        return cardDeviation;
      },
      totalCards,
    );

    const cardDeviationPercentatge = cardDeviation / totalCards;

    return deviation + cardDeviationPercentatge * vote.totalVotes;
  }, 0);

  const deviationPercentatge = deviation / totalVotes;

  // regla de 3, 0.2 seria el 100%
  return Math.max(0, Math.round(100 - (100 * deviationPercentatge) / 0.2));
};

export const getMostVotedCardsFromResults = (results: Results): string[] => {
  let mostVotedCards = [results.votes[0]];

  for (const vote of results.votes) {
    if (vote.totalVotes > mostVotedCards[0].totalVotes) {
      mostVotedCards = [vote];
    }

    if (vote.totalVotes === mostVotedCards[0].totalVotes) {
      mostVotedCards.push(vote);
    }
  }

  return mostVotedCards.map((vote) => vote.card);
};

import cx from 'classnames';
import { useRef, useState } from 'react';
import { Draggable } from 'react-smooth-dnd';

import styles from './IssueCard.module.scss';
// import { VoteIssueTooltip } from './VoteIssueTooltip';
import { Issue } from '../../../../types';
import { LoadingButton } from '@atlaskit/button';
import Lozenge from '@atlaskit/lozenge';

import Button from '@atlaskit/button/standard-button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { B500 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip';
import Image from '@atlaskit/image';

import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { openIssueModal } from '../../../../jira/actions';
import { useIssueActions } from '../../../hooks/useIssueActions';
import { PointsPicker } from '../../../../../components/PointsPicker/PointsPicker';
import { useGameContext } from '../../../hooks/useGameContext';
import { Inline, Stack } from '@atlaskit/primitives';
import { useAuthContext } from '../../../../auth/useAuthContext';
import { useSidebarLayoutContext } from '../../SidebarLayoutWrapper/useSidebarLayoutContext';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { IssueResultsModal } from '../../IssueResultsModal/IssueResultsModal';

interface IssueCardProps {
  issue: Issue;
  index: number;
}

export const IssueCard = ({ issue, index }: IssueCardProps) => {
  const {
    setVotingIssue,
    unsetVotingIssue,
    editIssueStoryPoints,
    moveIssue,
    deleteIssue,
  } = useIssueActions();
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [_isOpen, setIsSettingsSidebarOpen] =
    useSidebarLayoutContext().settingsSidebar;

  const appId = auth && auth.appId;
  const [isLoadingIssueModal, setIsLoadingIssueModal] = useState(false);
  const [isIssueResultsModalOpen, setIsIssueResultsModalOpen] = useState(false);
  const game = gameContext?.game;
  const isInEditingMode = useSidebarLayoutContext().manageIssuesPanel[0];
  const deckCards = gameContext?.deckCards;

  // const [hasClickedVoteIssue, setHasClickedVoteIssue] = useState(false);
  const voteIssueRef = useRef<HTMLDivElement>(null);

  const ctaLabel = issue.isVotingNow
    ? 'Voting now...'
    : !!issue.storyPoints || issue.storyPoints === 0
      ? 'Vote again'
      : 'Vote this issue';

  const handleIsVotingChange = () => {
    // setHasClickedVoteIssue(true);

    if (issue.isVotingNow) {
      unsetVotingIssue();
    } else {
      setVotingIssue(issue);
    }
  };

  const handleOpenIssue = () => {
    setIsLoadingIssueModal(true);

    openIssueModal(issue.key, () => {
      setIsLoadingIssueModal(false);
    });
  };

  const handleDelete = () => {
    setIsConfirmDeleteOpen(false);
    deleteIssue(issue);
  };

  const handleMoveTop = () => {
    if (appId) {
      moveIssue(appId, index, 0);
    }
  };

  const handleMoveBot = () => {
    if (appId && game && game.issues?.length) {
      moveIssue(appId, index, game.issues.length - 1);
    }
  };

  const handleWatchResults = () => {
    setIsIssueResultsModalOpen(true);
  };

  if (!game) {
    return null;
  }

  return (
    <>
      <Draggable
        key={issue.id}
        render={() => (
          <div>
            <div
              className={cx(
                styles['issue-card'],
                issue.isVotingNow && styles['issue-card--voting'],
                // isContextMenuOpen && styles['issue-card--menu-open'],
              )}
            >
              <div className={styles['issue-card__container']}>
                <Stack space="space.200">
                  <Stack space="space.100">
                    <Inline spread="space-between" alignBlock="center">
                      <Inline space="space.100" alignBlock="center">
                        {issue.issueType ? (
                          <Tooltip content={issue.issueType.name}>
                            {(tooltipProps) => (
                              <span {...tooltipProps} style={{ height: 18 }}>
                                <Image
                                  src={issue.issueType?.iconUrl}
                                  alt={issue.issueType?.name}
                                  width={18}
                                  height={18}
                                />
                              </span>
                            )}
                          </Tooltip>
                        ) : null}{' '}
                        <LoadingButton
                          isLoading={isLoadingIssueModal}
                          appearance="link"
                          onClick={handleOpenIssue}
                          spacing="none"
                        >
                          {issue.key}
                        </LoadingButton>
                        {issue.epic && (
                          <Lozenge appearance="new" isBold maxWidth={90}>
                            {issue.epic.name}
                          </Lozenge>
                        )}
                      </Inline>
                      <div className={styles['context-menu']}>
                        <DropdownMenu
                          trigger={({ triggerRef, ...props }) => (
                            <Button
                              {...props}
                              iconBefore={<MoreIcon label="more" />}
                              appearance="subtle"
                              ref={triggerRef}
                            />
                          )}
                        >
                          <DropdownItemGroup>
                            <DropdownItem onClick={handleOpenIssue}>
                              Open
                            </DropdownItem>
                            <DropdownItem onClick={handleMoveTop}>
                              Move to top
                            </DropdownItem>
                            <DropdownItem onClick={handleMoveBot}>
                              Move to bottom
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setIsConfirmDeleteOpen(true)}
                            >
                              Remove
                            </DropdownItem>
                          </DropdownItemGroup>
                        </DropdownMenu>
                      </div>
                    </Inline>
                    <div>{issue.summary}</div>
                  </Stack>
                  <Inline spread="space-between">
                    <Inline space="space.100">
                      <div ref={voteIssueRef}>
                        <Button
                          className={'is-clickable'}
                          appearance={issue.isVotingNow ? 'primary' : 'default'}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleIsVotingChange();
                          }}
                        >
                          {ctaLabel}
                        </Button>
                      </div>
                      {isInEditingMode && (
                        <Button
                          iconBefore={
                            <TrashIcon
                              label="delete"
                              primaryColor="var(--ds-background-danger-bold, #DE350B)"
                            />
                          }
                          appearance="subtle"
                          onClick={() => setIsConfirmDeleteOpen(true)}
                        />
                      )}
                    </Inline>
                    <Inline space="space.100" alignBlock="center">
                      <Inline alignBlock="center">
                        {(issue.syncStatus === 'notSynced' ||
                          issue.syncStatus === 'syncing') && (
                          <Tooltip content="Saving story points">
                            {(tooltipProps) => (
                              <div {...tooltipProps}>
                                <Spinner size="small" />
                              </div>
                            )}
                          </Tooltip>
                        )}
                        {issue.syncStatus === 'synced' && (
                          <Tooltip content="Story points saved">
                            {(tooltipProps) => (
                              <div {...tooltipProps}>
                                <UploadIcon
                                  label="Synched"
                                  primaryColor={token(
                                    'color.icon.success',
                                    B500,
                                  )}
                                />
                              </div>
                            )}
                          </Tooltip>
                        )}
                        {issue.syncStatus === 'error' && (
                          <Tooltip
                            content={
                              <>
                                <p>Error saving story points</p>
                                <p>
                                  {issue.syncErrorMessage ||
                                    'Fix it at game settings'}
                                </p>
                              </>
                            }
                          >
                            {(tooltipProps) => (
                              <div {...tooltipProps}>
                                <Button
                                  onClick={() => setIsSettingsSidebarOpen(true)}
                                  appearance="subtle"
                                  iconBefore={
                                    <UploadIcon
                                      label="Error"
                                      primaryColor={token(
                                        'color.icon.danger',
                                        B500,
                                      )}
                                    />
                                  }
                                />
                              </div>
                            )}
                          </Tooltip>
                        )}
                      </Inline>
                      {issue.votings?.length && (
                        <Tooltip content="View results">
                          {(tooltipProps) => (
                            <div {...tooltipProps}>
                              <Button
                                onClick={handleWatchResults}
                                appearance="subtle"
                                iconBefore={<WatchIcon label="View results" />}
                              />
                            </div>
                          )}
                        </Tooltip>
                      )}
                      <PointsPicker
                        value={
                          issue.storyPoints || issue.storyPoints === 0
                            ? issue.storyPoints
                            : null
                        }
                        onChange={(card) => {
                          editIssueStoryPoints(
                            issue.id,
                            game.id,
                            card,
                            game.storyPointsField || null,
                          );
                        }}
                        deckCards={deckCards || []}
                        containerBackgroundColor={
                          issue.isVotingNow ? 'primary-white' : 'grey100'
                        }
                        align="left"
                        isInIssueCard
                      />
                    </Inline>
                  </Inline>
                </Stack>
              </div>
            </div>
          </div>
        )}
      />
      <ModalTransition>
        {isConfirmDeleteOpen && (
          <Modal onClose={() => setIsConfirmDeleteOpen(false)}>
            <ModalHeader>
              <ModalTitle appearance="danger">
                Remove issue: {issue.key}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>Are you sure you want to remove {issue.key} from the game?</p>
              <p>
                (The issue won't be deleted from Jira, just removed from this
                game)
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="subtle"
                onClick={() => setIsConfirmDeleteOpen(false)}
              >
                Cancel
              </Button>
              <Button appearance="danger" onClick={handleDelete}>
                Remove issue
              </Button>
            </ModalFooter>
          </Modal>
        )}
        {isIssueResultsModalOpen && (
          <IssueResultsModal
            issue={issue}
            onClose={() => setIsIssueResultsModalOpen(false)}
          />
        )}
      </ModalTransition>
    </>
  );
};

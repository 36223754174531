import React from 'react';

export const Eye = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V6.04484C9.79325 6.15359 8.64269 6.45723 7.58005 6.92404L6.89443 5.55279C6.64744 5.05881 6.04676 4.85858 5.55279 5.10557C5.05881 5.35256 4.85858 5.95324 5.10557 6.44721L5.82849 7.89306C4.00011 9.13451 2.56525 10.9122 1.74988 13C3.35002 17.0974 7.33604 20 12 20C16.664 20 20.65 17.0974 22.2501 13C21.4348 10.9122 19.9999 9.13451 18.1715 7.89306L18.8944 6.44721C19.1414 5.95324 18.9412 5.35256 18.4472 5.10557C17.9532 4.85858 17.3526 5.05881 17.1056 5.55279L16.4199 6.92404C15.3573 6.45723 14.2068 6.15359 13 6.04484V4ZM3.93496 13C5.40896 15.966 8.46905 18 12 18C15.531 18 18.591 15.966 20.065 13C18.591 10.034 15.531 8 12 8C8.46905 8 5.40896 10.034 3.93496 13ZM8 13C8.01454 10.7969 9.79691 9.01454 12 9C14.188 9 16 10.812 16 13C16 15.1893 14.188 17 12 17C9.81067 17 8 15.1893 8 13ZM10 12C10.0036 11.4492 10.4492 11.0036 11 11C11.547 11 12 11.453 12 12C12 12.5473 11.547 13 11 13C10.4527 13 10 12.5473 10 12Z"
      fill="currentColor"
    />
  </svg>
);

import { hideCards, updateIssueStoryPoints } from './../data/actions';
import { showNotification } from './../../jira/actions';
import { useGameContext } from './useGameContext';
import { Issue, IssueJira } from '../../types';
import { Field, getIssueByKey } from '../../jira/api';
import update from 'immutability-helper';
import { updateGame } from '../data/actions';
import { useAuthContext } from '../../auth/useAuthContext';
import { useFieldsContext } from '../../jira/fields/useFieldsContext';

export const useIssueActions = () => {
  const gameContext = useGameContext();
  const authContext = useAuthContext();
  const fieldsContext = useFieldsContext();
  const appId = authContext && authContext.appId;
  const uid = authContext && authContext.uid;
  const user = authContext && authContext.user;
  const game = gameContext && gameContext.game;
  const guessedStoryPointsField = fieldsContext?.guessedStoryPointsField;
  const storyPointsField =
    gameContext?.game?.storyPointsField || guessedStoryPointsField || null;

  const currentUserCanEditIssues = !!(
    !game?.whoCanEditIssues ||
    (uid && game.whoCanEditIssues.find((playerId) => playerId === uid))
  );

  const handleNoPermissions = () => {
    showNotification({
      title: "You don't have permission to manage issues",
      body: 'Change "who can manage issues" at game settings',
      type: 'error',
    });
  };

  const setVotingIssue = async (issue: Issue | number) => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    if (
      !appId ||
      !gameContext ||
      !gameContext.game?.id ||
      !gameContext.game.issues ||
      gameContext.game.issues.length === 0
    )
      return;

    const issueIndex =
      typeof issue === 'number'
        ? issue
        : gameContext.game.issues.findIndex(({ key }) => issue.key === key);

    if (issueIndex < 0 || issueIndex >= gameContext.game.issues.length) return;

    const newIssues: Issue[] = gameContext.game.issues.map((issue, index) => {
      return index === issueIndex
        ? {
            ...issue,
            isVotingNow: true,
          }
        : {
            ...issue,
            isVotingNow: false,
          };
    });

    await updateGame(appId, gameContext.game.id, {
      issues: newIssues,
    });

    if (gameContext.game.id && gameContext.game.cardsUp) {
      hideCards(appId, gameContext.game.id);
    }
    // if (
    //   gameContext.game.id &&
    //   gameContext.game.timerDurationMinutes &&
    //   gameContext.game.timerAutoRestart
    // ) {
    //   startTimerForGame(pokerTable.id, pokerTable.timerDurationMinutes);
    // }
  };

  const addIssues = async (issuesToAddJira: IssueJira[]) => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    const gameId = gameContext?.game?.id;

    if (!gameContext || !gameId || !appId || !uid) {
      return;
    }

    const gameIssues = gameContext.game?.issues;

    const filteredGameIssues = gameIssues
      ? gameIssues.filter(
          (issueGame) =>
            !issuesToAddJira.find((issue) => {
              return issueGame.key === issue.key;
            }),
        )
      : [];

    const isIssuesEmpty = !filteredGameIssues.length;

    const issuesToAdd: Issue[] = issuesToAddJira.map((issueJira, index) => {
      const issue: Issue = {
        ...issueJira,
        addedByUid: uid,
        isVotingNow: isIssuesEmpty && !gameContext.game?.cardsUp && index === 0,
        syncStatus: 'idle',
        syncErrorMessage: null,
      };

      return issue;
    });

    const newIssues = [...filteredGameIssues, ...issuesToAdd];

    await updateGame(appId, gameId, { issues: newIssues });
  };

  const addIssueByKey = async (issueKey: string) => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    const gameId = gameContext?.game?.id;

    if (!gameContext || !gameId || !appId || !uid || !user) {
      return;
    }

    const issueJira = await getIssueByKey(issueKey, storyPointsField);

    if (!issueJira) {
      throw new Error('Issue not found');
    }

    return addIssues([issueJira]);
  };

  const deleteAll = async () => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    const gameId = gameContext?.game?.id;

    if (!gameContext || !gameId || !appId || !uid) {
      return;
    }

    await updateGame(appId, gameId, { issues: [] });
  };

  const unsetVotingIssue = () => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    if (
      !appId ||
      !gameContext ||
      !gameContext.game?.id ||
      !gameContext.game.issues
    )
      return;

    const newIssues: Issue[] = gameContext.game.issues.map((issue) => {
      return {
        ...issue,
        isVotingNow: false,
      };
    });

    updateGame(appId, gameContext.game.id, {
      issues: newIssues,
    });
  };

  const moveIssue = (appId: string, oldIndex: number, newIndex: number) => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    if (
      !appId ||
      !gameContext ||
      !gameContext.game?.id ||
      !gameContext.game.issues
    )
      return;

    return updateGame(appId, gameContext.game.id, {
      issues: update(gameContext.game.issues, {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, gameContext.game.issues[oldIndex]],
        ],
      }),
    });
  };

  const voteNextIssue = () => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    if (
      !appId ||
      !gameContext ||
      !gameContext.game?.id ||
      !gameContext.game.issues ||
      gameContext.game.issues.length === 0
    )
      return;

    setVotingIssue(
      gameContext.currentIssueIndex === null
        ? 0
        : gameContext.currentIssueIndex + 1,
    );
  };

  const deleteIssue = (issue: Issue) => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    if (
      !appId ||
      !gameContext ||
      !gameContext.game?.id ||
      !gameContext.game.issues
    )
      return;

    const issues = gameContext.game.issues.filter(
      (_issue) => _issue.key !== issue.key,
    );

    return updateGame(appId, gameContext.game.id, {
      issues: issues,
    });
  };

  const editIssueStoryPoints = async (
    issueId: string,
    gameId: string,
    storyPoints: string | null,
    storyPointsField: Field | null,
  ) => {
    if (!currentUserCanEditIssues) {
      handleNoPermissions();

      return;
    }

    if (
      !appId ||
      !gameContext ||
      !gameContext.game?.id ||
      !gameContext.game.issues ||
      gameContext.game.issues.length === 0
    )
      return;

    const update: Partial<Issue> = {
      storyPoints: storyPoints,
      syncStatus: 'notSynced',
    };

    await updateGame(appId, gameContext.game.id, {
      issues: gameContext.game.issues.map((issue) => {
        if (issue.id === issueId) {
          return {
            ...issue,
            ...update,
          };
        }

        return issue;
      }),
    });

    return updateIssueStoryPoints({
      appId,
      issueId,
      storyPoints,
      storyPointsField,
      gameId,
    });
  };

  const saveAllFailedIssues = async (storyPointsField: Field) => {
    if (!game?.issues) {
      return;
    }
    const failedIssues = game.issues.filter(
      (issue) =>
        issue.syncStatus === 'error' ||
        issue.syncStatus === 'syncing' ||
        issue.syncStatus === 'notSynced',
    );

    if (!failedIssues.length) {
      return;
    }

    return Promise.all(
      failedIssues.map((issue) =>
        editIssueStoryPoints(
          issue.id,
          game?.id,
          issue.storyPoints as string,
          storyPointsField,
        ),
      ),
    );
  };

  return {
    addIssueByKey,
    addIssues,
    deleteAll,
    unsetVotingIssue,
    voteNextIssue,
    setVotingIssue,
    editIssueStoryPoints,
    moveIssue,
    deleteIssue,
    saveAllFailedIssues,
  };
};

import { signInWithCustomToken } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState, useMemo } from 'react';
import { auth, functions } from '../../firebase';
import { showNotification } from '../jira/actions';
import { getUserJira } from '../jira/api';
import * as Sentry from '@sentry/react';

export const SIGNIN_ERROR_MESSAGES = {
  NOT_LICENSED: `This app requires an active license.`,

  INVALID_INSTALL_DATA: `Permission denied`,
  JWT_NOT_FOUND: `Permission denied`,
  INVALID_ISS: `Permission denied`,
  INVALID_JWT: `Permission denied`,
  JWT_DECODE_ERROR: `Permission denied`,
  JIRA_USER_NOT_FOUND: `Permission denied`,
  JIRA_USER_NOT_VALID: `Permission denied`,
  INVALID_ACCOUNT_ID: `Permission denied`,

  USER_CREATION_ERROR: `Internal error, please try login again`,
  FIREBASE_USER_CREATION_ERROR: `Internal error, please try login again`,
  UNKNOWN_ERROR_GETTING_INSTALL_DATA: `Internal error, please try login again`,
};

const signIn = async () => {
  const [jiraUser, jwt] = await Promise.all([
    getUserJira(),
    window.AP.context.getToken(),
  ]);

  const result = await httpsCallable(functions, 'jiraConnectAppLogin', {
    timeout: 200000,
  })({ jwt, jiraUser });

  const { firebaseJWT, ...loginInfo } = result.data as {
    firebaseJWT: string;
    uid: string;
    clientKey: string;
  };

  await signInWithCustomToken(auth, firebaseJWT);

  return loginInfo;
};

export type SignInResult =
  | { uid: string; appId: string; isAuthenticated: true }
  | { error: Error; isAuthenticated: false }
  | null;

export const useSignIn = (): SignInResult => {
  const [uid, setUid] = useState<null | string>(null);
  const [appId, setAppid] = useState<null | string>(null);
  const [error, setError] = useState<null | Error>(null);

  useEffect(() => {
    signIn()
      .then(({ clientKey, uid }) => {
        setAppid(clientKey);
        setUid(uid);
      })
      .catch((e) => {
        setError(e);
        console.error(e);
        Sentry.withScope((scope) => {
          scope.setFingerprint(["Couldn't log in"]);
          Sentry.captureException(e);
        });
      });
  }, []);

  return useMemo(() => {
    if (error) {
      return { error, isAuthenticated: false };
    }

    if (!uid || !appId) {
      return null;
    }

    return { uid, appId, isAuthenticated: true };
  }, [uid, appId, error]);
};

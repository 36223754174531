import { HoritzontalSpacing } from '@we-agile-you/react-base';
import cx from 'classnames';
import React from 'react';
import { Deck, Results } from '../../../types';
import {
  getAgreementFromResults,
  getAverageFromResults,
  getMostVotedCardsFromResults,
  mapPlayersToResults,
} from '../../data/utils';
import { useGameContext } from '../../hooks/useGameContext';
import { Agreement } from './Agreement/Agreement';

import styles from './Results.module.scss';
import { StatsItem } from './StatsItem/StatsItem';
import { StatsKpi } from './StatsKpi/StatsKpi';

const Results = ({
  resultsOverwrite,
  deckCardsOverwrite,
}: {
  resultsOverwrite?: Results;
  deckCardsOverwrite?: string[];
}) => {
  const gameContext = useGameContext();
  const resultsGame = gameContext?.game?.results;
  const results =
    resultsOverwrite ||
    (resultsGame &&
      gameContext?.players &&
      mapPlayersToResults(gameContext?.players)) ||
    null;
  const game = gameContext?.game;
  const deckCards = deckCardsOverwrite || gameContext?.deckCards;

  if (!results || !results.votes.length || !deckCards || !game)
    return <div>No votes</div>;

  const totalPlayers = results.total || 0;
  const average = getAverageFromResults(results);
  const agreement = getAgreementFromResults(deckCards, results);

  const cardsWithVotes = results.votes
    .filter((vote) => !!vote.playersVoted.length)
    .sort((voteA, voteB) =>
      deckCards.indexOf(voteA.card) < deckCards.indexOf(voteB.card) ? -1 : 1,
    );
  const mostVotedCards = getMostVotedCardsFromResults(results);

  return (
    <div className={cx(styles['stats'])}>
      <ul className={cx(styles['stats-items'])}>
        {cardsWithVotes.map(({ card, playersVoted }) => (
          <li key={card}>
            <StatsItem
              card={card}
              cardVotes={playersVoted.length}
              totalVotes={totalPlayers}
              isMostVoted={mostVotedCards.includes(card)}
            />
          </li>
        ))}
      </ul>
      {(average !== null || agreement !== null) && (
        <>
          <HoritzontalSpacing spacing="spacing-xxl" />
          <div className={cx(styles['kpis'])}>
            <>
              {average !== null && !game.hideAverageInResults && (
                <StatsKpi
                  label="Average:"
                  value={Math.round(average * 10) / 10}
                />
              )}
              {game.id && <Agreement agreement={agreement} />}
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default Results;

import { Player as PlayerType } from '../../../../types';
import { Projectile, ProjectileType } from '@we-agile-you/types-planning-poker';
import cx from 'classnames';
import { useRef, useState, useEffect, useCallback } from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import Button from '@atlaskit/button';

import styles from './Player.module.scss';
import { Icon } from '@we-agile-you/react-base';
import Card from '../../Card/Card';
import Tooltip from '@atlaskit/tooltip';
import { useGameContext } from '../../../hooks/useGameContext';
import Avatar from '@atlaskit/avatar';
import {
  getIsReflected,
  getTrajectoryIndex,
  ProjectileEvent,
} from '../../Projectile/Projectile';
import { usePopover } from 'react-use-popover';
import { ProjectileSelector } from '../../Projectile/ProjectileSelector';
import { Projectile as ProjectileComponent } from '../../Projectile/Projectile';
import { PointsPicker } from '../../../../../components/PointsPicker/PointsPicker';
import { ButtonFloating } from '../../../../../components/ButtonFloating/ButtonFloating';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { deletePlayer } from '../../../data/actions';
import { useAuthContext } from '../../../../auth/useAuthContext';

export const Player = ({
  player,
  position,
  incomingProjectiles,
  onShootProjectile,
  onProjectileShootComplete,
  onIsProjectilePickerOpenChange,
  onChangePoints,
  hasProjectileSelector,
  isCurrentUserPlayer,
  isCurrentUserTheOwner,
  cardsUpOverwrite,
  isResultsTable,
}: {
  player: PlayerType;
  position: 'top' | 'left' | 'bottom' | 'right';
  incomingProjectiles: Projectile[];
  onShootProjectile: (projectileType: ProjectileType, emoji?: string) => void;
  onProjectileShootComplete: (projectileId: string) => void;
  onIsProjectilePickerOpenChange: (isOpen: boolean | null) => void;
  onChangePoints: (card: string | null) => void;
  hasProjectileSelector: boolean;
  isCurrentUserPlayer: boolean;
  isCurrentUserTheOwner: boolean;
  cardsUpOverwrite?: boolean;
  isResultsTable?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const projectilesWrapperRef = useRef<HTMLDivElement>(null);
  const profileContainerRef = useRef<HTMLDivElement>(null);
  const [isMouseOverPlayer, setIsMouseOverPlayer] = useState(false);
  const [isMouseOverProjectile, setIsMouseOverProjectile] = useState(false);
  const [isProjectilePickerOpen, setIsProjectilePickerOpen] = useState<
    boolean | null
  >(null);
  const [projectileEvents, setProjectileEvents] = useState<{
    [key: string]: ProjectileEvent;
  }>({});
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const { displayName, avatar } = player;
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const cardsUp =
    !!cardsUpOverwrite || (!!gameContext && !!gameContext.game?.cardsUp);

  const currentUserCanDeletePlayer =
    isCurrentUserTheOwner && !isCurrentUserPlayer;

  const hasCardPicker = isCurrentUserPlayer && !!cardsUp && !isResultsTable;

  const hasPicker =
    !isResultsTable &&
    (hasProjectileSelector || currentUserCanDeletePlayer || hasCardPicker);

  const isProjectileSelectorOpen =
    hasCardPicker ||
    (hasPicker &&
      isProjectilePickerOpen !== false &&
      (isProjectilePickerOpen ||
        (isMouseOverPlayer && !isMouseOverProjectile)));
  const game = gameContext?.game;
  const deckCards = gameContext?.deckCards;
  const { appId } = auth;

  const handleIsProjectilePickerOpen = useCallback(
    (isOpen: boolean | null) => {
      onIsProjectilePickerOpenChange(isOpen);
      setIsProjectilePickerOpen(isOpen);
    },
    [onIsProjectilePickerOpenChange],
  );

  const convertToEvent = useCallback(
    (projectile: Projectile) => {
      const projectileEvent: ProjectileEvent = {
        id: projectile.id,
        projectileType: projectile.projectileType,
        emoji: projectile.emoji,
        isReflected: getIsReflected(),
        trajectoryIndex: getTrajectoryIndex(),
      };
      if (position === 'left') {
        projectileEvent.isReflected = true;
      }
      if (position === 'right') {
        projectileEvent.isReflected = false;
      }

      return projectileEvent;
    },
    [position],
  );

  const wrapperElement = wrapperRef.current;

  useEffect(() => {
    if (!isProjectilePickerOpen) return;

    const handleDocumentClick = (event: Event) => {
      if (!wrapperElement?.contains(event.target as Node)) {
        handleIsProjectilePickerOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [wrapperElement, isProjectilePickerOpen, handleIsProjectilePickerOpen]);

  useEffect(() => {
    const pendingEvents = incomingProjectiles.map((projectile) =>
      convertToEvent(projectile),
    );
    setProjectileEvents((currentEvents) => {
      const newEvents = { ...currentEvents };
      pendingEvents.forEach((event) => {
        if (!currentEvents[event.id]) {
          newEvents[event.id] = event;
        }
      });
      return newEvents;
    });
  }, [incomingProjectiles, convertToEvent]);

  const removeProjectile = (projectileId: string) => {
    setProjectileEvents((currentProjectiles) => {
      const newProjectiles = { ...currentProjectiles };
      delete newProjectiles[projectileId];
      return newProjectiles;
    });
    onProjectileShootComplete(projectileId);
  };

  const handleMouseEnterCard = () => {
    if (isProjectilePickerOpen === false) {
      handleIsProjectilePickerOpen(null);
    }

    setIsMouseOverProjectile(false);
  };

  const handleDeletePlayer = () => {
    if (!game?.id) return;

    deletePlayer(appId, player.uid, game?.id);
    setIsConfirmDeleteOpen(false);
  };

  const { dropdownStyle } = usePopover({
    anchorRef: wrapperRef,
    dropdownElement: projectilesWrapperRef.current,
    position: 'top',
    marginFromAnchor: 0,
    moveOnScroll: true,
    hookDeps: [cardsUp, position, hasPicker, isProjectileSelectorOpen],
    updateAtInterval: hasCardPicker ? 200 : undefined,
  });

  const className = cx(
    styles['player-wrapper'],
    styles[position],
    !displayName && styles['no-display-name'],
    isMouseOverPlayer && styles['player-wrapper--hover'],
    hasPicker && styles['with-projectile-selector'],
    isProjectileSelectorOpen && styles['with-projectile-selector--open'],
    isProjectilePickerOpen === false &&
      styles['with-projectile-selector--closed'],
  );

  return (
    <>
      <div
        className={className}
        ref={wrapperRef}
        onMouseEnter={() => setIsMouseOverPlayer(true)}
        onMouseLeave={() => setIsMouseOverPlayer(false)}
      >
        {hasPicker && isProjectilePickerOpen !== false && (
          <div
            className={cx(styles.projectileSelectorContainer)}
            ref={projectilesWrapperRef}
            style={dropdownStyle}
          >
            {hasProjectileSelector && (
              <ProjectileSelector
                onShootProjectile={onShootProjectile}
                onIsEmojiPickerOpenChange={(isOpen) => {
                  if (!isOpen) {
                    window.setTimeout(
                      () => handleIsProjectilePickerOpen(true),
                      0,
                    );

                    return;
                  }

                  handleIsProjectilePickerOpen(true);
                }}
              />
            )}
            {currentUserCanDeletePlayer && (
              <ButtonFloating
                size="s"
                buttonStyle="tooltip"
                className={styles.delete}
                onClick={() => setIsConfirmDeleteOpen(true)}
                tooltip="Kick player out"
              >
                <TrashIcon
                  label="delete"
                  primaryColor="var(--ds-background-danger, #DE350B)"
                />
              </ButtonFloating>
            )}
            {hasCardPicker && (
              <PointsPicker
                value={cardsUp ? player.vote || '' : ''}
                onChange={onChangePoints}
                deckCards={deckCards || []}
                isCardPicker
              />
            )}
          </div>
        )}

        {player.isSpectator ? (
          <div
            className={cx(styles['card-container'], styles['is-spectator'])}
            onMouseEnter={handleMouseEnterCard}
          >
            <Icon icon="eye2" />
          </div>
        ) : (
          <div
            className={cx(
              styles['card-container'],
              !player.vote && styles['is-empty'],
            )}
            onMouseEnter={handleMouseEnterCard}
          >
            {!!player.vote && <Card card={player.vote} isCardsUp={cardsUp} />}
          </div>
        )}
        <div className={styles['profile-container']} ref={profileContainerRef}>
          {avatar ? (
            <div className={styles['profile-picture']}>
              <Tooltip content={displayName}>
                {(tooltipProps) => (
                  <div {...tooltipProps}>
                    <Avatar name={displayName} src={avatar} size="medium" />
                  </div>
                )}
              </Tooltip>
            </div>
          ) : (
            <div
              className={cx(styles['player-name'], 'notranslate')}
              translate="no"
            >
              {displayName}
            </div>
          )}
          {Object.values(projectileEvents).map((event) => (
            <ProjectileComponent
              key={event.id}
              event={event}
              onComplete={() => removeProjectile(event.id)}
              onClick={() => {
                handleIsProjectilePickerOpen(false);
              }}
              onMouseEnter={() => setIsMouseOverProjectile(true)}
              onMouseLeave={() => setIsMouseOverProjectile(false)}
            />
          ))}
        </div>
      </div>
      <ModalTransition>
        {isConfirmDeleteOpen && (
          <Modal onClose={() => setIsConfirmDeleteOpen(false)}>
            <ModalHeader>
              <ModalTitle appearance="warning">Kick player out</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                Are you sure you want to kick {displayName} out of the game?
              </p>
              <p>(He/she will be able to come back any time)</p>
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="subtle"
                onClick={() => setIsConfirmDeleteOpen(false)}
              >
                Cancel
              </Button>
              <Button appearance="warning" onClick={handleDeletePlayer}>
                Kick player out
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
